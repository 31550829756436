import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest } from '@share/helpers'
import type { CompanyLegalEntitiesModel } from '@share/schemas'
import { companyLegalEntitiesSchema } from '@share/schemas'
import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import * as yup from 'yup'

type GetCompanyLegalEntityRequest = {
  companyId: string
  withDisabled: boolean
}

type GetCompanyLegalEntityResponse = {
  legalEntities: CompanyLegalEntitiesModel
}

const responseSchema = yup.object({
  legalEntities: companyLegalEntitiesSchema.defined(),
})

const getCompanyLegalEntityRequest = (params?: GetCompanyLegalEntityRequest) =>
  apiRequest<GetCompanyLegalEntityResponse>({
    url: API_LIST.company.legalEntities.root,
    responseSchema,
    params,
  })

type UseGetCompanyLegalEntity = Partial<{
  params: GetCompanyLegalEntityRequest
  options: UseQueryOptions<GetCompanyLegalEntityResponse>
}>

export const useGetCompanyLegalEntity = (props?: UseGetCompanyLegalEntity) =>
  useQuery<GetCompanyLegalEntityResponse>({
    queryKey: [API_TAGS.company.legalEntities.root, props?.params],
    queryFn: () => getCompanyLegalEntityRequest(props?.params),
    ...props?.options,
  })
