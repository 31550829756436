import * as yup from 'yup'

import { orderSubtypeSchema, orderTypeSchema } from '../order'

import { VRFStatusesSchema } from './vrf-statuses.schema'

export const VRFTableModelSchema = yup
  .object({
    id: yup.string().defined(),
    name: yup.string().defined(),
    customName: yup.string().defined().nullable(),
    status: VRFStatusesSchema.defined(),
    type: orderTypeSchema.defined(),
    subtype: orderSubtypeSchema.defined(),
    client: yup.string().defined().nullable(),
    deliveryCountry: yup.object({
      id: yup.string().defined(),
      name: yup.string().defined(),
      flag: yup.string().defined(),
    }),
    formSubmissionDate: yup.string().defined(),
    daysAfterCreation: yup.number().defined(),
    requestedEta: yup
      .object({
        fromDate: yup.string().nullable(),
        toDate: yup.string().nullable(),
      })
      .defined(),
    vendorResponseForms: yup
      .object({
        total: yup.number().defined(),
        filled: yup.number().defined(),
      })
      .defined(),
    expireDate: yup.string().defined().nullable(),
  })
  .noUnknown()

export const VRFTableModelsSchema = yup.array().of(VRFTableModelSchema.defined()).defined()

export type VRFTableModel = yup.InferType<typeof VRFTableModelSchema>
export type VRFTableModels = yup.InferType<typeof VRFTableModelsSchema>
