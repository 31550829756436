import type { ResponseWithResult } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest } from '@share/helpers'
import type { OrderSubtype, OrderType } from '@share/schemas'
import { getResponseWithResultSchema } from '@share/schemas'
import type { PaymentInformationModel } from '@share/schemas/payment'
import { paymentInformationModelSchema } from '@share/schemas/payment'
import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

type GetPaymentInformationRequest = {
  type: OrderType
  subtype: OrderSubtype
}
type GetPaymentInformationResponse = ResponseWithResult<PaymentInformationModel>

const responseSchema = getResponseWithResultSchema(paymentInformationModelSchema)

const getPaymentInformationRequest = (params: GetPaymentInformationRequest) =>
  apiRequest<GetPaymentInformationResponse>({
    url: API_LIST.dictionaries.payment.information,
    params,
    responseSchema,
  })

interface UseGetPaymentInformation {
  params: GetPaymentInformationRequest
  options?: UseQueryOptions<GetPaymentInformationResponse>
}

export const useGetPaymentInformation = ({ params, options }: UseGetPaymentInformation) =>
  useQuery({
    queryKey: API_TAGS.dictionaries.paymentInformation(),
    queryFn: () => getPaymentInformationRequest(params),
    ...options,
  })
