import * as yup from 'yup'

import { companyStaffModelSchema } from '../company'
import { cityModelSchema, countryModelSchema, stateModelSchema } from '../country'
import { officeModelSchema } from '../office'
import { warehouseModelSchema } from '../warehouse'

import { deliveryProofsSchema } from './delivery-proofs.schemas'

const deliveryCountryModelSchema = countryModelSchema
  .pick(['id', 'name', 'flag', 'alphaCode2'])
  .defined()

const possibleDeliveryType = ['DELIVERY', 'STOCKING'] as const
export type DeliveryType = (typeof possibleDeliveryType)[number]
export const possibleDeliveryTypeSchema = yup.mixed<DeliveryType>().oneOf(possibleDeliveryType)

// Possible types of assignee
const possibleAssigneeType = ['STAFF', 'OFFICE', 'WAREHOUSE', 'COUNTRY'] as const
export type AssigneeType = (typeof possibleAssigneeType)[number]
export const possibleAssigneeTypeSchema = yup.mixed<AssigneeType>().oneOf(possibleAssigneeType)

export const deliveryInfoSchema = yup.object({
  id: yup.string().required().defined(),
  office: officeModelSchema.pick(['id', 'name', 'address', 'image']).defined().nullable(),
  warehouse: warehouseModelSchema.pick(['id', 'name']).defined().nullable(),
  staff: companyStaffModelSchema.pick(['id', 'fullName', 'avatar', 'address']).defined().nullable(),
  dateEnd: yup.string().nullable().defined(),
  dateStart: yup.string().nullable().defined(),
  actualEta: yup.tuple([yup.string().defined(), yup.string().optional()]).nullable().defined(),
  address: yup.object().shape({
    addressLine1: yup.string().defined(),
    addressLine2: yup.string().defined().nullable(),
    city: cityModelSchema.pick(['id', 'name']).defined().nullable(),
    state: stateModelSchema.pick(['id', 'name']).defined().nullable(),
    zipCode: yup.string().defined().nullable(),
    country: deliveryCountryModelSchema.defined(),
  }),
  price: yup.number().nullable().defined(),
  tracking: yup.object().shape({
    number: yup.string().nullable().defined(),
    link: yup.string().nullable().defined(),
  }),
  delivery: yup.object().shape({
    startDate: yup.string().nullable().defined(),
    endDate: yup.string().nullable().defined(),
  }),
  deliveryDate: yup.string().defined().nullable(),
  contactInformation: yup.object().shape({
    staff: companyStaffModelSchema.pick(['id', 'fullName', 'avatar', 'address']).defined(),
    fullName: yup.string().defined(),
    email: yup.string().defined(),
    privateEmail: yup.string().defined(),
    phone: yup.string().defined(),
    receiverId: yup.string().defined().nullable(),
    receiverSignatureNeeded: yup.boolean().defined(),
  }),
  notes: yup.string().nullable().defined(),
  proofsOfDelivery: deliveryProofsSchema.defined(),
  assigneeType: possibleAssigneeTypeSchema.defined(),
})

export const deliveriesInfoModelSchema = yup.array().of(deliveryInfoSchema).defined()

export type DeliveryInfoModel = yup.InferType<typeof deliveryInfoSchema>
export type DeliveriesInfoModel = yup.InferType<typeof deliveriesInfoModelSchema>

export const deliveryInfoRequestSchema = yup.object({
  addressLine1: yup.string().defined(),
  addressLine2: yup.string().defined().nullable(),
  city: yup.string().defined(),
  state: yup.string().defined(),
  zipCode: yup.string().defined(),
  contactInformation: yup
    .object({
      fullName: yup.string().defined(),
      email: yup.string().email().defined(),
      privateEmail: yup.string().email().defined().nullable(),
      phone: yup.string().defined(),
    })
    .defined(),
})

export type DeliveryInfoRequest = yup.InferType<typeof deliveryInfoRequestSchema>
